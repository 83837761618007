import React from 'react'
import '../Styles/Resturent.css'
import { useNavigate} from 'react-router';
import { Link } from 'react-router-dom';

const Resturent = () => {
    const navigation = useNavigate();
    const buttons = [
        { title: 'Filters 1', id: 'Filters 1' },
        { title: 'Restuarent', id: 'Restuarent' },
        { title: 'Cake Shop', id: 'Cake Shop' },
        { title: 'Sweet Shop', id: 'Sweet Shop' },
        { title: 'Special Chat', id: 'Special Chat' },
        { title: 'Bakery', id: 'Bakery' },
    ];

    const stores = [
        {
            id: 1,
            imgSrc: 'assets/resturent1.jpeg',
            title: 'Home Kitchen',
            name: "By a professional a chef",
            rating: '4.5',
            time: '30 mins',
            price:40,
        },
        {
            id: 2,
            imgSrc: 'assets/resturent2.jpg',
            title: 'Dawat',
            name: "সব থেকে আচ্ছা দোকান 👌 👌",
            rating: '4.0',
            time: '25 mins',
            price: 70,
        },
        {
          id: 2,
          imgSrc: 'assets/resturent3.jpg',
          title: 'Kolkata Data Boudi Biriyani',
          name: "Good Briyani",
          rating: '4.0',
          time: '25 mins',
          price:190,
      },
    ];

    const handleStoreClick = (store) => {
        navigation('/page-details', { state: { store } });
    };
    


    return (
        <div>
            <header className="header">
            <div className="logo">
                    <Link to="/"><img style={{ width: "72px" }} src={require('../../Assets/zotpot.png')} /></Link>
                </div>
                <div className="location">
                    <span>Arambagh</span>
                </div>
            </header>
            <div className="button-container">
                <div className="scrollable" style={{overflowX:"auto",scrollbarWidth: "none",}}>
                    {buttons.map((button) => (
                        <button
                            key={button.id}
                            className="button"
                        >
                            {button.title}
                        </button>
                    ))}
                </div>
            </div>
            <div className="stores-grid">
                    {stores.map((store) => (
                        <div className="store-item" key={store.id} onClick={() => handleStoreClick(store)}>
                            <img src={store.imgSrc} alt={store.name} />
                            <div style={{ marginLeft: "20px", display: "flex", flexDirection: "column", justifyContent: "normal", alignItems: "flex-start", }}>
                                <h3>{store.title}</h3>
                                <p>{store.name}</p>
                                <div className="store-details">
                                    <span>{store.rating}</span>
                                    <span>{store.time}</span>
                                    <span>{store.price}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        </div >
    )
}

export default Resturent
