import React, { useEffect, useState } from 'react';
import '../Styles/Cart.css';
import { useDispatch, useSelector } from 'react-redux';
import {incrementQuantity, decrementQuantity } from '../Redux/Cart_System';
import { FaAngleLeft } from "react-icons/fa6";
import { SlHome } from "react-icons/sl";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from '../Core/Footer';

const Cart = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [store, setStore] = useState(null);
    const [products, setProducts] = useState([]);
    const cartItems = useSelector((state) => state.cart.items);
    const totalAmount = useSelector((state) => state.cart.totalAmount);


    useEffect(() => {
        if (cartItems.length > 0) {
            setStore(cartItems[0].store);
            const allProducts = cartItems.map(item => item.product);
            setProducts(allProducts);
            console.log("Store:", cartItems[0].store);
            console.log("Products:", allProducts);
        }
    }, [cartItems]);

    const [quantity, setQuantity] = useState(1);
    const [tip, setTip] = useState(null);
    const [coupon, setCoupon] = useState('');

    const handlePositiveChange = (productId) => {
        dispatch(incrementQuantity(productId));
    };

    const handleNegativeChange = (productId) => {
        dispatch(decrementQuantity(productId));
    };

    const handleTipSelect = (value) => {
        setTip(value);
    };

    const handleCheckout = (totalAmount) => {
        var totalAmount = totalAmount + 23;
        console.log("Total : ", totalAmount)
        navigation('/checkout', { state: { totalAmount } });
    };


    return (
        <div style={{    padding: "0px 20px",}}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "770px",
                    margin: "auto"
                }}
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
                    <FaAngleLeft onClick={() => navigation(-1)} />
                    <p style={{ marginLeft: "23px" }}>CART</p>
                </div>
                <div style={{ display: "flex", gap: "20px", marginRight: "13px" }}>
                    <Link to="/"><SlHome /></Link>
                </div>
            </div>
            {cartItems.length > 0 ?
                <div className="cart-container">
                    {store ? (
                        <div style={{height:"109px"}}  className="store-item" key={store.id}>
                            <img style={{width: "111px"}} src={store.imgSrc} alt={store.name} />
                            <div
                                style={{
                                    marginLeft: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                }}
                            >
                                <h3 style={{margin:"0px"}}>{store.title}</h3>
                                <p style={{margin:"0px"}}>{store.name}</p>
                                <div className="store-details">
                                    <span>{store.rating}</span>
                                    <span>{store.time}</span>
                                    <span>{store.price}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No store information available</p>
                    )}

                    <div className='Cart-all-item'>
                        <h3 style={{
                            margin: '0px', padding: "10px 0px",
                            borderBottom: "1px solid #dddada"
                        }}>ITEMS IN CART</h3>
                        {products.map((product, index) => (
                            <div className="cart-item" key={product.id}>
                                <div className="item-name">
                                    <span>{product.title}</span>
                                </div>
                                <div className="quantity-control">
                                    <button onClick={() => handleNegativeChange(product.id)}>-</button>
                                    <span>{product.quantity}</span>
                                    <button onClick={() => handlePositiveChange(product.id)}>+</button>
                                </div>
                                <div className="price">₹{product.price * product.quantity}</div>
                            </div>
                        ))}
                        <div className="advanced-order">
                            <input style={{
                                width: "92%",
                                border: "none",
                                padding: "10px 12px",
                                outline: "none",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            }} placeholder='ADVANCED ORDER (Delivery Time)/ suggestion for order' type='text' />
                        </div>

                    </div>


                    {/* Advanced Order Section */}
                    {/* <div className="advanced-order">
                <FaClock /> ADVANCED ORDER (Delivery Time) / suggestion for order
            </div> */}


                    {/* Tip your delivery partner section */}
                    <div className="tip-section">
                        <h3>TIP YOUR DELIVERY PARTNER</h3>
                        <p>Thank your delivery partner for helping you stay safe indoors. Support them through these tough times with a tip.</p>
                        <div className="tip-options">
                            {[5, 10, 20].map(value => (
                                <button
                                    key={value}
                                    onClick={() => handleTipSelect(value)}
                                    className={tip === value ? 'selected' : ''}
                                >
                                    ₹{value}
                                </button>
                            ))}
                            <button onClick={() => handleTipSelect('other')}>Other</button>
                        </div>
                    </div>

                    {/* Coupon section */}
                    <div className="coupon-section">
                        <input
                            type="text"
                            placeholder="COUPON"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                        />
                        <button className="apply-btn">Apply</button>
                    </div>
                    <div className="bill-container">
                        <div className="bill-details">
                            <h3>BILL DETAILS</h3>
                            <div className="bill-item">
                                <span>Item Total</span>
                                <span>₹{totalAmount}</span>
                            </div>
                            <div className="bill-item">
                                <span>Delivery Charges <span className="distance">(2.6km)</span></span>
                                <span>₹23.00</span>
                            </div>
                            <div className="bill-item total">
                                <span>To Pay</span>
                                <span>₹{totalAmount + 23}</span>
                            </div>
                        </div>
                        <div className="zigzag"></div>
                    </div>
                    <button onClick={() => { handleCheckout(totalAmount) }} className='btn-proceed'>PROCEED TO CHECKOUT</button>
                </div>
                : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh", }}> Ypur Cart is Empty contact 9564779055</div>
            }
            <Footer />
        </div >
    );
};

export default Cart;
