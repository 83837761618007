import React from 'react'
import '../Styles/Profile.css'
import Footer from '../Core/Footer'
import { IoMdHome } from "react-icons/io";
import { MdOutlineShoppingBag } from "react-icons/md";
import { SlWallet } from "react-icons/sl";
import { FaRegHeart } from "react-icons/fa";

const Profile = () => {
    return (
        <div style={{ width: "100%", backgroundColor: "#f2f2f2" }}>
            <div className="profile-header">
                <h2>Saifuddin Mondal</h2>
                <p>+919564779055</p>
                <p>saifuddinmondal2580@gmail.com</p>
            </div>
            <div className="profile-page">
                <div className="account-section">"
                    <h3>My Account</h3>
                    <div className="account-options">
                        <div className="account-option">
                            <div style={{position:"relative",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",fontSize: "24px"}}>
                                <div style={{backgroundColor: "#5d5dff",width: "40px",height: "40px",borderRadius: "50%",}}></div>
                                <span style={{position: "absolute",top: "6px",left: "40px",color: "white",fontSize: "24px"}}><IoMdHome style={{color:"white"}}/></span>
                                <span>Manage Address</span>
                            </div>
                        </div>
                        <div className="account-option">
                        <div style={{position:"relative",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",fontSize: "24px"}}>
                                <div style={{backgroundColor: "#ed04f8",width: "40px",height: "40px",borderRadius: "50%",}}></div>
                                <span style={{position: "absolute",top: "6px",left: "22px",color: "white",fontSize: "24px"}}><MdOutlineShoppingBag style={{color:"white"}}/></span>
                                <span>My Orders</span>
                            </div>
                        </div>
                        <div className="account-option">
                        <div style={{position:"relative",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",fontSize: "24px"}}>
                                <div style={{backgroundColor: "green",width: "40px",height: "40px",borderRadius: "50%",}}></div>
                                <span style={{position: "absolute",top: "6px",left: "22px",color: "white",fontSize: "24px"}}><SlWallet style={{color:"white"}}/></span>
                                <span>My Wallet</span>
                            </div>
                        </div>
                        <div className="account-option">
                        <div style={{position:"relative",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",fontSize: "24px"}}>
                                <div style={{backgroundColor: "red",width: "40px",height: "40px",borderRadius: "50%",}}></div>
                                <span style={{position: "absolute",top: "6px",left: "31px",color: "white",fontSize: "24px"}}><FaRegHeart style={{color:"white"}}/></span>
                                <span>My Favourites</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="help-section">
                    <h3>Help & FAQs</h3>
                    <div className="help-options">
                        <button className="help-option">Contact Us</button>
                        <button className="help-option">Privacy Policy</button>
                        <button className="help-option">Terms & Conditions</button>
                        <button className="help-option">Cancellation and Refund</button>
                        <button className="help-option">Shipping and Delivery</button>
                    </div>
                </div>

                <div className="logout-section">
                    <button className="logout-btn">Logout</button>
                </div>

                <div className="language-section">
                    <span>Change Language</span>
                    <select>
                        <option value="en">English</option>
                        <option value="hi">Hindi</option>
                    </select>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Profile
