import React from 'react';
import '../Styles/Footer.css'
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { IoIosSearch } from "react-icons/io";
import { IoBagOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div style={{ width: "100%", margin: "auto",
            }}>
            <div className="bottom-nav">
                <Link to="/">
                    <div className="nav-item active">
                        <span className="icon"><IoLocationOutline /></span>
                        <span className="label">Near Me</span>
                    </div></Link>
                <div className="nav-item">
                    <span className="icon"><HiOutlineBellAlert /></span>
                    <span className="label">Alerts</span>
                    {0 > 0 && <span className="notification-badge">0</span>}
                </div>
                <div className="nav-item">
                    <span className="icon"><IoIosSearch /></span>
                    <span className="label">Explore</span>
                </div>
                <Link to='/cart'>
                    <div className="nav-item">
                        <span className="icon"><IoBagOutline /></span>
                        <span className="label">Cart</span>
                        {1 > 0 && <span className="notification-badge">1</span>}
                    </div>
                </Link>
                <Link to="/profile">
                    <div className="nav-item">
                        <span className="icon"><SlUser /></span>
                        <span className="label">Account</span>
                    </div></Link>
            </div>
        </div>
    );
};

export default Footer;
