import React, { useState } from 'react';
import { FaAngleLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router';

const CashChangePage = () => {
    const location = useLocation();
    const navigation = useNavigate();
    const { totalAmount } = location.state || {};
    const [cashChangeAmount, setCashChangeAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleConfirm = () => {
        if (cashChangeAmount !== '' && parseFloat(cashChangeAmount) === totalAmount) {
            navigation('/success-page');
        } else {
            setErrorMessage('Please enter the correct amount.');
        }
    };

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <FaAngleLeft onClick={() => navigation(-1)} style={styles.backButton} />
                <div style={{ marginLeft: "18px" }}>
                    <span style={styles.toPayText}>TO PAY</span>
                    <span style={styles.amount}>₹{totalAmount}</span>
                </div>
            </header>

            <div style={styles.content}>
                <h3 style={styles.instructionText}>Enter cash change amount</h3>

                <input
                    type="number"
                    value={cashChangeAmount}
                    onChange={(e) => {
                        setCashChangeAmount(e.target.value);
                        setErrorMessage(''); 
                    }}
                    placeholder="Enter amount"
                    style={styles.inputField}
                />

                {errorMessage && <p style={styles.errorText}>{errorMessage}</p>}

                <button style={styles.confirmButton} onClick={handleConfirm}>
                    CONFIRM
                </button>

                <p style={styles.noteText}>
                    Leave the field empty if you don't require any cash change for Cash On Delivery of your order.
                </p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        backgroundColor: '#fff',
        height: '100vh',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    backButton: {
        fontSize: '24px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    toPayText: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    amount: {
        fontSize: '18px',
        color: 'orange',
        fontWeight: 'bold',
    },
    content: {
        textAlign: 'center',
    },
    instructionText: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    inputField: {
        width: '80%',
        padding: '10px',
        fontSize: '18px',
        borderRadius: '5px',
        marginBottom: '10px',
        outline:"none",
        border:"none",
        boxShadow:"box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;"
    },
    confirmButton: {
        marginTop: "27px",
        border: "none",
        outline: "none",
        backgroundColor: '#4CAF50',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '18px',
        border: '1px solid black',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    errorText: {
        color: 'red',
        fontSize: '14px',
        marginBottom: '10px',
    },
    noteText: {
        fontSize: '14px',
        color: '#777',
    },
};

export default CashChangePage;
