import React from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router';
import { FaAngleLeft } from "react-icons/fa6";

const PaymentPage = () => {
    const location = useLocation();
    const navigation=useNavigate()
    const { totalAmount } = location.state || {};
    const handleCheckout = (totalAmount) => {
        navigation('/pay', { state: { totalAmount } });
    };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
      <FaAngleLeft onClick={() => navigation(-1)}/>
        <div style={{marginLeft: "18px"}}>
        <span style={styles.toPayText}>TO PAY</span>
        <span style={styles.amount}>₹{totalAmount}</span>
        </div>
      </header>

      <div style={styles.content}>
        <h3 style={styles.selectText}>Select your preferred payment method</h3>

        <div style={styles.paymentOption} onClick={()=>{handleCheckout(totalAmount)}}>
          <div style={styles.iconWrapper}>
            <FaMoneyBillWave size={40} color="#6eb8f7" />
          </div>
          <div>
            <h4 style={styles.paymentText}>COD</h4>
            <p style={styles.subText}>Cash On Delivery</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#fff',
    height: '100vh',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  backButton: {
    fontSize: '24px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  toPayText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  amount: {
    fontSize: '18px',
    color: 'orange',
    fontWeight: 'bold',
  },
  content: {
    textAlign: 'center',
  },
  selectText: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  paymentOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    cursor: 'pointer',
    backgroundColor: '#f9f9f9',
  },
  iconWrapper: {
    marginRight: '20px',
  },
  paymentText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  subText: {
    color: '#777',
  },
};

export default PaymentPage;