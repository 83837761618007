import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
    totalItems: 0,
    totalAmount: 0,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addCart: (state, action) => {
            console.log("Product : ", action.payload.product);
            console.log("Store : ", action.payload.store);
            const { product, store } = action.payload;

            const existingItem = state.items.find(item => item.product.id === product.id);
            console.log("Existing Item: ", existingItem);

            if (state.items.length > 0 && state.items[0].store.title !== store.title) {
                const userConfirmed = window.confirm("You have items from a different store in your cart. Do you want to replace them?");
                if (userConfirmed) {
                    state.items = [];
                    state.items.push({
                        product: { ...product, quantity: 1 }, 
                        store,
                        price: Number(product.price) || 0
                    });
                }
            } else {
                if (existingItem) {
                    existingItem.product.quantity += 1; 
                } else {
                    state.items.push({
                        product: { ...product, quantity: 1 }, 
                        store,
                        price: Number(product.price) || 0
                    });
                }
            }

            state.totalItems = state.items.reduce((total, item) => total + item.product.quantity, 0); 
            state.totalAmount = state.items.reduce((total, item) => {
                return total + (Number(item.price) || 0) * (Number(item.product.quantity) || 0); 
            }, 0);

            console.log("Updated Cart Items: ", JSON.parse(JSON.stringify(state.items)));
        },

        removeCart: (state, action) => {
            const { product } = action.payload;
            const existingItem = state.items.find(item => item.product.id === product.id);

            if (existingItem) {
                if (existingItem.product.quantity > 1) {
                    existingItem.product.quantity -= 1; 
                } else {
                    state.items = state.items.filter(item => item.product.id !== product.id);
                }
            }

            if (state.items.length === 0) {
                state.items = [];
            }

            state.totalItems = state.items.reduce((total, item) => total + item.product.quantity, 0); 
            state.totalAmount = state.items.reduce((total, item) => {
                return total + (Number(item.price) || 0) * (Number(item.product.quantity) || 0); 
            }, 0);

            console.log("Redux Item After Removal: ", JSON.parse(JSON.stringify(state.items)));
        },
        incrementQuantity: (state, action) => {
            const productId = action.payload;
            console.log("productId : ",productId)
            const item = state.items.find(item => item.product.id === productId);

            if (item) {
                item.product.quantity += 1;
                state.totalItems += 1;
                state.totalAmount += item.price;
                console.log("Working correct.........")
            }
            console.log("Total Item : ",state.totalAmount)
        },

        decrementQuantity: (state, action) => {
            const productId = action.payload;
            console.log("productId : ",productId)
            const item = state.items.find(item => item.product.id === productId);

            if (item) {
                if (item.product.quantity > 1) {
                    item.product.quantity -= 1;
                    state.totalItems -= 1;
                    state.totalAmount -= item.price;
                    console.log("Working correct.........")
                } else {
                    state.items = state.items.filter(item => item.product.id !== productId);
                    state.totalItems = state.items.reduce((total, item) => total + item.product.quantity, 0);
                    state.totalAmount = state.items.reduce((total, item) => total + (Number(item.price) || 0) * (Number(item.product.quantity) || 0), 0);
                }
            }
            console.log("Total Item : ",state.totalAmount)
        }
    },
});

export const { addCart, removeCart,incrementQuantity,decrementQuantity} = cartSlice.actions;
export default cartSlice.reducer;
