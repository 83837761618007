import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { SlHome } from "react-icons/sl";
import { IoShareSocialOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { addCart, removeCart } from '../Redux/Cart_System';

const PageDetails = () => {
    const navigation=useNavigate()
    const dispatch=useDispatch();
  const products = [
    {
      id: 1,
      title: "BadaPau",
      price: 100,
      imgSrc: require("../../Assets/BadaPau.png"),
    },
    {
      id: 2,
      title: "Burger",
      price: 110,
      imgSrc: require("../../Assets/Burger.png"),
    },
    {
      id: 3,
      title: "Donuthut",
      price: 50.5,
      imgSrc: require("../../Assets/Donuthut.png"),
    },
  ];

  const stores = [
    {
        id: 1,
        imgSrc: 'assets/item1.png',
        title: 'The Sugar & Spice',
        name: "The Cake Shop",
        rating: '4.5',
        time: '30 mins',
        price: 110,
    },
    {
        id: 2,
        imgSrc: 'assets/item2.png',
        title: 'Aiyan Chicken Shop',
        name: "Fresh Chicken",
        rating: '4.0',
        time: '25 mins',
        price: 130,
    },
];

  const location = useLocation();
  const { store } = location.state || {};

  // console.log("store : ",store)

  const handleStoreClick = (product, store) => {
    navigation('/product-details', { state: { product, store } });
  };  

  const handleAddToCart = (product, store) => {
    dispatch(addCart({ product, store }));
  };
  
  const handleRemoveFromCart = (product, store) => {
    dispatch(removeCart({ product, store }));
  };
  
  

  if (!store) {
    return <div>No Store Data Available</div>;
  }

  return (
    <div style={{ padding: "20px",backgroundColor: "#f9f9f9" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "23px",
        }}
      >
        <FaAngleLeft onClick={() => navigation(-1)}/>
        <div style={{ display: "flex", gap: "20px" }}>
        <Link to="/"><SlHome /></Link>
          <IoShareSocialOutline />
        </div>
      </div>
      <div style={{height:"109px"}} className="store-item" key={store.id}>
        <img style={{width: "111px"}} src={store.imgSrc} alt={store.name} />
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <h3 style={{margin:"0px"}}>{store.title}</h3>
          <p style={{margin:"0px"}}>{store.name}</p>
          <div className="store-details">
            <span>{store.rating}</span>
            <span>{store.time}</span>
            <span>{store.price}</span>
          </div>
        </div>
      </div>
      <div className="store-list">
        <div className="search" style={{ marginTop: "30px" }}>
          <input
            style={{ margin: "0 auto", width: "85%" }}
            className="input"
            type="text"
            placeholder="Search for stores or items..."
          />
        </div>
      </div>
      <div>
        <h4
          style={{
            marginTop: "25px",
            marginBottom: "15px",
            fontWeight: "bold",
          }}
        >
          RECOMMENDED
        </h4>
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
            margin:"0px",
            overflowX: "auto",
            scrollbarWidth: "none", // For Firefox
          }}
          id="product-list"
        >
          {products.map((product) => (
            <div
              key={product.id}
              style={{
                height: "222px",
                width: "197px",
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  borderRadius: "10px",
                  height: "100%",
                  
                }}
              >
                <figure style={{ height: "50%", margin: 0 }} onClick={() => handleStoreClick(product,store)}>
                  <div style={{ height: "100%", display: "block" }}>
                    <img
                      src={product.imgSrc}
                      alt={product.title}
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        transition: "0.5s",
                      }}
                    />
                  </div>
                  {product.status && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        padding: "2px 6px",
                        borderRadius: "3px",
                      }}
                    >
                      {product.status}
                    </div>
                  )}
                </figure>
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <h6 style={{ margin: "5px 0" }}>{product.title}</h6>
                  <p style={{ margin: "5px 0" }}>{product.price}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  <button onClick={() => handleRemoveFromCart(product,store)} style={{ width: "40%", padding: "5px" }}>-</button>
                  <button onClick={() => handleAddToCart(product, store)} style={{ width: "40%", padding: "5px" }}>+</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h5>Biriyani And Chap</h5>
      </div>
      <div className="stores-grid">
        {stores.map((product) => (
          <div
            style={{ height: "75px",display:"flex",    alignItems: "center",justifyContent: "space-between" }}
            className="store-item"
            key={product.id}
          >
            <div style={{display:"flex",height:"100%"}} onClick={() => handleStoreClick(product,store)}>
              <img
                style={{ width: "83px",
                  height: "100%" }}
                src={product.imgSrc}
                alt={product.name}
              />
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "normal",
                  alignItems: "flex-start",
                  height:"100%"
                }}
              >
                <p style={{ margin: "0px" }}>{store.title}</p>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "7px" }}
                >
                  <button
                    style={{
                      backgroundColor: "#fb2c2cfa",
                      color: "white",
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                      padding: "3px 5px",
                    }}
                  >
                    Recomended
                  </button>
                  <button
                    style={{
                      backgroundColor: "orangered",
                      color: "white",
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                      padding: "3px 5px",
                    }}
                  >
                    Popular
                  </button>
                </div>
                <p style={{marginTop:"7px"}}>{product.price}</p>
              </div>
            </div>
            <div style={{display:"flex",gap: "2px"}}>
              <button onClick={() => handleRemoveFromCart(product,store)} style={{ padding: "3px 15px" }}>-</button>
              <button onClick={() => handleAddToCart(product, store)} style={{ padding: "3px 15px" }}>+</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageDetails;
