import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import { SlHome } from "react-icons/sl";
import { IoShareSocialOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const location = useLocation();
  const navigation=useNavigate();
  const { product, store } = location.state || {};

  console.log(product);
  console.log("store : ", store)

  if (!product || !store) {
    return <div>No Product or Store Data Available</div>;
  }
  

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "23px",
        }}
      >
        <FaAngleLeft onClick={() => navigation(-1)}/>
        <div style={{ display: "flex", gap: "20px" }}>
        <Link to="/"><SlHome /></Link>
          <IoShareSocialOutline />
        </div>
      </div>
      <div className="store-item" key={store.id} style={{"height":"109px"}}>
        <img style={{width: "111px"}} src={store.imgSrc} alt={store.name} />
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <h3 style={{margin:"0px"}}>{store.title}</h3>
          <p style={{margin:"0px"}}>{store.name}</p>
          <div className="store-details">
            <span>{store.rating}</span>
            <span>{store.time}</span>
            <span>{store.price}</span>
          </div>
        </div>
      </div>
      <div className="stores-grid">

        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", width: "100%" }}
          className="store-item"
          key={product.id}
        >
          <img
            style={{ width: "100%" }}
            src={product.imgSrc}
            alt={product.name}
          />
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
            <div style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "normal",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ marginBottom: "0px" }}>{store.title}</p>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "11px" }}
                >
                  <button
                    style={{
                      backgroundColor: "#fb2c2cfa",
                      color: "white",
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                      padding: "3px 5px",
                    }}
                  >
                    Recomended
                  </button>
                  <button
                    style={{
                      backgroundColor: "orangered",
                      color: "white",
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                      padding: "3px 5px",
                    }}
                  >
                    Popular
                  </button>
                </div>
                <p>{product.price}</p>
              </div>
            </div>
            <div style={{ display: "flex", gap: "2px" }}>
              <button style={{ padding: "3px 25px" }}>-</button>
              <button style={{ padding: "3px 25px" }}>+</button>
            </div>
          </div>
        </div>

      </div>


    </div>
  );
};

export default ProductDetails;


