import {Route,Routes} from 'react-router'
import Home from './Component/Pages/Home';
import Profile from './Component/Pages/Profile';
import PageDetails from './Component/Pages/PageDetails';
import ProductDetails from './Component/Pages/ProductDetails';
import Resturent from './Component/Pages/Resturent';
import Cart from './Component/Pages/Cart';
import PaymentPage from './Component/Pages/PaymentPage';
import CashChangePage from './Component/Pages/CashChangePage';
import SuccessPage from './Component/Pages/SuccessPage';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path="/page-details" element={<PageDetails/>} />
        <Route path="/product-details" element={<ProductDetails/>} />
        <Route path="/all-resturent" element={<Resturent/>} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/checkout" element={<PaymentPage/>} />
        <Route path="/pay" element={<CashChangePage/>} />
        <Route path="/success-page" element={<SuccessPage/>} />
      </Routes>
    </div>
  );
}

export default App;
