import React from 'react';
import '../Styles/Home.css';
import Footer from '../Core/Footer';
import { useNavigate } from 'react-router';
import whatsapp from "../../Assets/whatsapp-873316_1280.png";
import { Link } from 'react-router-dom';
// import { FaSearch } from "react-icons/fa";

const Home = () => {
    const navigation = useNavigate();
    const stores = [
        {
            id: 1,
            imgSrc: 'assets/item1.png',
            title: 'The Sugar & Spice',
            name: "The Cake Shop",
            rating: '4.5',
            time: '30 mins',
            price: 130,
        },
        {
            id: 2,
            imgSrc: 'assets/item2.png',
            title: 'Aiyan Chicken Shop',
            name: "Fresh Chicken",
            rating: '4.0',
            time: '25 mins',
            price: 150,
        },
    ];

    const handleStoreClick = (store) => {
        navigation('/page-details', { state: { store } });
    };

    const handleResturent = () => {
        navigation('/all-resturent');
    };

    return (
        <div className="app-container">
            <div className='home-whatsapp-wrapper'>
                <Link to="https://wa.me/918100098024"><img className='whatsapp-img' src={whatsapp} width="50" alt='...' /></Link>
            </div>
            <header className="header">
                <div className="logo">
                    <Link to="/"><img style={{ width: "72px" }} src={require('../../Assets/zotpot.png')} /></Link>
                </div>
                <div className="location">
                    <span>Arambagh</span>
                </div>
            </header>
            <div style={{
                padding: "20px", marginTop: "70px"
            }}>
                <div style={{
                    display: "flex", gap: "18px", width: "100%",
                    overflowX: "auto",
                    scrollbarWidth: "none",
                }}>
                    <div className='home-item-image' style={{ width: "187px", }}>
                        <img style={{ width: "187px", }} src={require('../../Assets/discount-item-1.png')} alt="..." />
                    </div>
                    <div className='home-item-image' style={{ width: "187px", }}>
                        <img style={{ width: "187px", }} src={require('../../Assets/discount-item-3.png')} alt="..." />
                    </div>
                    <div className='home-item-image' style={{ width: "187px", }}>
                        <img style={{ width: "187px", }} src={require('../../Assets/discount-item-4.png')} alt="..." />
                    </div>
                </div>
                <div className="store-list">
                    <div className="search">
                        <input className='input' type="text" placeholder="Search for stores or items..." />
                    </div>
                </div>
                <div style={{ marginTop: "33px" }}>
                    <p style={{ fontWeight: "bold" }}>41 FOOD STORES NEAR YOU</p>
                    <div style={{
                        display: "flex", gap: "18px", marginTop: "33px", marginBottom: "22px", width: "100%",
                        overflowX: "auto",
                        scrollbarWidth: "none",
                    }}>
                        <div className='home-item-image1'>
                            <img style={{ width: "170px", }} src={require('../../Assets/discount-item-1.png')} alt="..." onClick={handleResturent} />
                            <p>Restuarents</p>
                        </div>
                        <div className='home-item-image1'>
                            <img style={{ width: "170px", }} src={require('../../Assets/discount-item-3.png')} alt="..." onClick={handleResturent} />
                            <p>Cake Shop</p>
                        </div>
                        <div className='home-item-image1'>
                            <img style={{ width: "170px", }} src={require('../../Assets/discount-item-4.png')} alt="..." onClick={handleResturent} />
                            <p>Special Chat</p>
                        </div>
                    </div>
                </div>
                <div className="stores-grid">
                    {stores.map((store) => (
                        <div style={{height:"100px"}} className="store-item" key={store.id} onClick={() => handleStoreClick(store)}>
                            <img style={{width:"105px"}} src={store.imgSrc} alt={store.name} />
                            <div style={{ marginLeft: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", }}>
                                <h3 style={{margin:"0px"}}>{store.title}</h3>
                                <p style={{margin:"0px"}}>{store.name}</p>
                                <div className="store-details">
                                    <span>{store.rating}</span>
                                    <span>{store.time}</span>
                                    <span>{store.price}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Home;
