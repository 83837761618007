import React from 'react';
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router';
import { SlHome } from "react-icons/sl";

const SuccessPage = () => {
    const navigation = useNavigate();
    const handleRefresh = () => {
        navigation("/")
    };

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FaAngleLeft onClick={() => navigation(-1)} style={styles.backButton} />
                    <span style={styles.orderId}>OrderId</span>
                </div>
                <button style={styles.homeButton}> <SlHome /></button>
            </header>

            <div style={styles.statusContainer}>
                <div style={{display:"flex",flexDirection:"column",gap: "12px"}}>
                    <h3 style={styles.statusText}>Order Placed Successfully</h3>
                    <p style={styles.statusSubText}>Waiting for the restaurant to confirm your order</p>
                </div>
                <div style={styles.statusIcon}>✔️</div>
            </div>

            <button style={styles.refreshButton} onClick={handleRefresh}>
                Refresh Order Status
            </button>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        backgroundColor: '#fff',
        height: '100vh',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    backButton: {
        fontSize: '24px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    orderId: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginLeft: "18px"
    },
    homeButton: {
        fontSize: '24px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    mapContainer: {
        width: '100%',
        height: '300px',
        marginBottom: '20px',
    },
    map: {
        height: '100%',
        width: '100%',
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        marginBottom: '20px',
        marginTop: "56px",
    },
    statusText: {
        fontSize: '18px',
        fontWeight: 'bold',
        margin:"0px"
    },
    statusSubText: {
        color: '#777',
        margin:"0px"
    },
    statusIcon: {
        fontSize: '32px',
        color: 'green',
    },
    refreshButton: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#4CAF50',
        color: '#fff',
        fontSize: '18px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: "22px"
    },
};

export default SuccessPage;